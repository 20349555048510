import React, { memo, useCallback } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import Button from './Button';

interface TeamProps {
  name: string;
  goals: number;
  onIncrementGoals: () => void;
  onDecrementGoals: () => void;
}

const Team = ({
  name,
  goals,
  onIncrementGoals,
  onDecrementGoals,
}: TeamProps) => {
  const onClickIncrementGoalsHandler = useCallback(() => {
    if (onIncrementGoals) onIncrementGoals();
  }, [onIncrementGoals]);

  const onClickDecrementGoalsHandler = useCallback(() => {
    if (onDecrementGoals) onDecrementGoals();
  }, [onDecrementGoals]);

  return (
    <div className="mx-2 p-6 border-b border-slate-500">
      <h2 className="text-white-100 text-xl font-medium mb-6">{name}</h2>
      <div className="grid grid-cols-4 gap-4 items-center">
        <Button
          icon={MinusIcon}
          onClick={onClickDecrementGoalsHandler}
          disabled={goals <= 0}
        />
        <span className="text-white text-8xl font-extrabold text-center col-span-2">
          {goals}
        </span>
        <Button icon={PlusIcon} onClick={onClickIncrementGoalsHandler} />
      </div>
    </div>
  );
};

export default memo(Team);
