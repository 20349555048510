import React from 'react';
import useFirebasePubliVideo from '../../hooks/useFirebasePubliVideo';
import ReactPlayer from 'react-player';
import clsx from 'clsx';

const IntervalAdvertising = () => {
  const { intervalVideo, intervalVideoStatus } = useFirebasePubliVideo();
  return (
    <div
      className={clsx('fixed inset-0 z-40 bg-black', {
        'top-[100%]': intervalVideoStatus !== 'show',
      })}
    >
      <div className="w-full aspect-video mx-auto">
        <ReactPlayer
          url={intervalVideo}
          width={'100%'}
          height={'100%'}
          playing={true}
          loop={true}
          muted={true}
          config={{
            vimeo: {
              playerOptions: {
                background: true,
              },
            },
            youtube: {
              onUnstarted: () => {
                // console.log('onUnstarted');
              },
              playerVars: {
                autoplay: 1,
                loop: 1,
                controls: 0,
                disablekb: 1,
                modestbranding: 1,
                rel: 0,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default IntervalAdvertising;
