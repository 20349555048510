import React from 'react';
import { formatTime } from '../../utils/formatTime';
import { GameStatus as GameStatusType } from '../../@types.types';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';

interface GameStatusProps {
  gameTime: number;
  gameStatus: GameStatusType;
  onResetTimer: () => void;
}

const gameStatusText: {
  [key in GameStatusType]: string;
} = {
  paused: 'Jogo Pausado:',
  idle: 'Jogo Parado',
  running: 'Jogo em Andamento: ',
  'tecnhical-time': 'Tempo Técnico',
};

const GameStatus = ({
  gameTime,
  gameStatus,
  onResetTimer,
}: GameStatusProps) => {
  const showGameTime = gameStatus === 'running' || gameStatus === 'paused';

  if (gameStatus === 'idle') return null;

  return (
    <div className="mb-1 py-4 flex justify-center sticky top-0  bg-zinc-900 z-10 before:block before:absolute  before:w-full before:h-[30px] before:bg-gradient-to-b before:from-zinc-900 before:left-0 before:right-0 before:bottom-[-30px]">
      <div className=" bg-white text-red-600 rounded px-4 py-2 flex items-center">
        <span className="text-[10px] uppercase leading-1 block mr-1">
          {gameStatusText[gameStatus]}
        </span>

        {showGameTime ? (
          <span
            className={`font-semibold text-xl leading-1 ${
              gameStatus === 'paused' ? 'animate-blink' : ''
            }`}
          >
            {formatTime(gameTime)}
          </span>
        ) : null}

        {gameTime <= 0 ? (
          <button
            className="w-8 aspect-square text-white-200 border border-white-300 rounded-lg relative ml-3 text-zinc-900"
            onClick={() => onResetTimer()}
          >
            <ArrowsRightLeftIcon className="w-4/6 aspect-square mx-auto" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default GameStatus;
