import { useCallback, useEffect, useState } from 'react';
import { database, firebaseAuth } from '../clients/firebase';
import { ref, onValue as onValueFirebase } from 'firebase/database';
import { User } from 'firebase/auth';

const firebaseDatabaseQuery = ref(database, 'admins');

const useFirebaseAdmins = () => {
  const [admins, setAdmins] = useState();

  useEffect(() => {
    return onValueFirebase(firebaseDatabaseQuery, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        setAdmins(data);
      }
    });
  }, []);

  const currentUser: User | null = firebaseAuth.currentUser;

  const currentUserIsAdmin = useCallback(() => {
    if (admins && currentUser) {
      return Object.keys(admins).includes(currentUser.uid);
    }
    return false;
  }, [currentUser, admins]);

  return {
    admins,
    currentUser,
    currentUserIsAdmin,
  };
};

export default useFirebaseAdmins;
