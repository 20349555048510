import React, { memo, useCallback, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import useFirebaseAdmins from '../hooks/useFirebaseAdmins';
import useFirebaseScoreboard from '../hooks/useFirebaseScoreboard';

interface Settings {
  teamAName: string;
  teamBName: string;
  gameDuration: number;
  showingTime: boolean;
}

interface ModalSettingsProps {
  handleCloseModal: () => void;
}

const ModalSettings = ({ handleCloseModal }: ModalSettingsProps) => {
  const [settings, setSettings] = useState<Partial<Settings>>({});

  const { currentUser } = useFirebaseAdmins();

  const { scoreboard, updateScoreboard } = useFirebaseScoreboard(currentUser);

  const {
    teams,
    duration: durationFirebase,
    showingTime: showingTimeFirebase,
  } = scoreboard;

  const {
    A: { name: teamANameFirebase },
    B: { name: teamBNameFirebase },
  } = teams;

  useEffect(() => {
    setSettings((prevState) => ({
      ...prevState,
      showingTime: showingTimeFirebase,
      ...(teamANameFirebase && { teamAName: teamANameFirebase }),
      ...(teamBNameFirebase && { teamBName: teamBNameFirebase }),
      ...(durationFirebase && { gameDuration: durationFirebase / 60 }),
    }));
  }, [
    teamANameFirebase,
    teamBNameFirebase,
    durationFirebase,
    showingTimeFirebase,
  ]);

  const handleSaveSettings = useCallback(
    ({ gameDuration, teamAName, teamBName, showingTime }: Settings) => {
      const durationLocal =
        gameDuration && !isNaN(gameDuration) ? gameDuration : 20;

      updateScoreboard({
        ...scoreboard,
        duration: durationLocal * 60,
        showingTime,
        teams: {
          A: { ...teams.A, name: teamAName },
          B: { ...teams.B, name: teamBName },
        },
      });
    },
    [],
  );

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-2 mx-auto w-full max-w-[400px] min-w-[320px] px-5">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-between px-3 py-2 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-sm text-black font-semibold">
                Configurações da Partida
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black w-8 h-8"
                onClick={() => handleCloseModal()}
              >
                <XMarkIcon className="w-4/6 aspect-square mx-auto" />
              </button>
            </div>
            <div className="relative py-4 px-3 flex-auto">
              <div className="w-full">
                <div className="mb-4">
                  <label
                    className="block text-zinc-900 text-xs uppercase font-bold mb-1"
                    htmlFor="timeA"
                  >
                    Time A:
                  </label>
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-zinc-900 leading-tight focus:outline-none focus:border-zinc-900"
                    id="timeA"
                    type="text"
                    value={settings.teamAName ?? ''}
                    onChange={({ target: { value } }) =>
                      setSettings({ ...settings, teamAName: value })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-zinc-900 text-xs uppercase font-bold mb-1"
                    htmlFor="timeB"
                  >
                    Time B:
                  </label>
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-zinc-900 leading-tight focus:outline-none focus:border-zinc-900"
                    id="timeB"
                    type="text"
                    value={settings.teamBName ?? ''}
                    onChange={({ target: { value } }) =>
                      setSettings({ ...settings, teamBName: value })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-zinc-900 text-xs uppercase font-bold mb-1"
                    htmlFor="duration"
                  >
                    Duração do Jogo:
                  </label>

                  <div className="relative">
                    <input
                      id="duration"
                      type="number"
                      value={settings.gameDuration ?? ''}
                      onChange={({ target: { value } }) => {
                        if (value) {
                          setSettings({
                            ...settings,
                            gameDuration: parseInt(value, 10),
                          });
                        } else {
                          const localSettings = settings;
                          delete localSettings.gameDuration;

                          setSettings({
                            ...localSettings,
                          });
                        }
                      }}
                      className="appearance-none border rounded w-full py-2 px-3 text-zinc-900 leading-tight focus:outline-none focus:border-zinc-900"
                    />
                    <span className="absolute inset-y-0 end-0 top-0 text-xs flex items-center pe-3.5 pointer-events-none text-zinc-900">
                      minutos
                    </span>
                  </div>
                </div>
                <div className="b-4">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value="show"
                      className="sr-only peer"
                      onChange={({ target: { checked } }) => {
                        setSettings({
                          ...settings,
                          showingTime: checked,
                        });
                      }}
                      checked={settings.showingTime ?? false}
                    />
                    <div className="w-11 h-6 bg-gray-200 outline-none peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-bold  text-zinc-900 ">
                      Mostrar tempo no placar
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleSaveSettings(settings as Settings);
                  handleCloseModal();
                }}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-95 fixed inset-0 z-40 bg-zinc-900" />
    </>
  );
};

export default memo(ModalSettings);
