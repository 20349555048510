import { useState, useEffect } from 'react';

interface CountdownProps {
  initialTime: number;
}

interface CountdownState {
  time: number;
  isRunning: boolean;
  startCountdown: () => void;
  pauseCountdown: () => void;
  resetCountdown: () => void;
  // eslint-disable-next-line no-unused-vars
  updateCountdown: (newTime: number) => void;
}

const useCountdown = ({ initialTime }: CountdownProps): CountdownState => {
  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);

  const startCountdown = () => {
    setIsRunning(true);
  };

  const pauseCountdown = () => {
    setIsRunning(false);
  };

  const resetCountdown = () => {
    setIsRunning(false);
    setTime(initialTime);
  };

  const updateCountdown = (newTime: number) => {
    setTime(newTime);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let interval: NodeJS.Timeout;

    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;

          if (newTime === 0) {
            clearInterval(interval);
            setIsRunning(false);
          }

          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  return {
    time,
    isRunning,
    startCountdown,
    pauseCountdown,
    resetCountdown,
    updateCountdown,
  };
};

export default useCountdown;
