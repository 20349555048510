import React from 'react';
import ReactPlayer from 'react-player';

interface VideoProps {
  videoUrl: string;
}

const Video = ({ videoUrl }: VideoProps) => (
  <ReactPlayer
    url={videoUrl}
    width={'100%'}
    height={'100%'}
    playing={true}
    loop={true}
    muted={true}
    config={{
      vimeo: {
        playerOptions: {
          background: true,
        },
      },
      youtube: {
        onUnstarted: () => {
          // console.log('onUnstarted');
        },
        playerVars: {
          autoplay: 1,
          loop: 1,
          controls: 0,
          disablekb: 1,
          modestbranding: 1,
          rel: 0,
        },
      },
    }}
  />
);

export default Video;
