import { useEffect, useState } from 'react';
import { sendTracking } from '../utils/tracking';
import { database } from '../clients/firebase';
import {
  ref,
  onValue as onValueFirebase,
  set as setFirebase,
} from 'firebase/database';
import { Advertisings } from '../@types.types';

const INITIAL_STATE: Advertisings = {
  scoreboardVideo: 'https://vimeo.com/900899039',
  intervalVideo: 'https://vimeo.com/900897918',
  scoreboardVideoStatus: 'show',
  intervalVideoStatus: 'hide',
};

const firebaseDatabaseQuery = ref(database, 'advertisings');

const useFirebasePubliVideo = () => {
  const [advertisings, setAdvertisings] = useState<Advertisings>(INITIAL_STATE);

  const {
    intervalVideo,
    intervalVideoStatus,
    scoreboardVideo,
    scoreboardVideoStatus,
  } = advertisings;

  const setAdvertisingsFirebase = (data: Partial<Advertisings>) => {
    setFirebase(firebaseDatabaseQuery, {
      ...advertisings,
      ...data,
    });
  };

  useEffect(() => {
    return onValueFirebase(firebaseDatabaseQuery, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        setAdvertisings(data);
      }
    });
  }, []);

  const toggleIntervalVideo = () => {
    sendTracking(
      advertisings.intervalVideoStatus === 'show'
        ? 'hide_interval_publi'
        : 'show_interval_publi',
    );
    setAdvertisingsFirebase({
      intervalVideoStatus:
        advertisings.intervalVideoStatus === 'show' ? 'hide' : 'show',
    });
  };

  return {
    intervalVideo,
    intervalVideoStatus,
    scoreboardVideo,
    scoreboardVideoStatus,
    toggleIntervalVideo,
  };
};

export default useFirebasePubliVideo;
