import { useEffect, useState } from 'react';
import { Scoreboard, Team } from '../@types.types';
import { sendTracking } from '../utils/tracking';
import { database } from '../clients/firebase';
import {
  ref,
  set as setFirebase,
  onValue as onValueFirebase,
} from 'firebase/database';
import useCountdown from './useCountdown';
import { User } from 'firebase/auth';

const INITIAL_STATE: Scoreboard = {
  teams: {
    A: {
      name: 'Time A',
      goals: 0,
    },
    B: {
      name: 'Time B',
      goals: 0,
    },
  },
  duration: 1200,
  status: 'idle',
  showingTime: true,
  lastUpdate: Date.now(),
};

const firebaseDatabaseQuery = ref(database, 'scoreboard');

type ChronometerEvents = 'START' | 'PAUSE' | 'RESUME' | 'RESET';

const useFirebaseScoreboard = (user?: User | null) => {
  const [isCountRunning, setIsCountRunning] = useState(false);
  const [scoreboard, setScoreboardState] = useState<Scoreboard>(INITIAL_STATE);

  const {
    time,
    updateCountdown,
    startCountdown,
    pauseCountdown: stopCountdown,
    resetCountdown,
  } = useCountdown({ initialTime: scoreboard.duration });

  useEffect(() => {
    return onValueFirebase(firebaseDatabaseQuery, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        setScoreboardState(data);

        if (data.status === 'running') {
          updateCountdown(
            Math.floor(
              (data.lastUpdate + data.remainingTime * 1000 - Date.now()) / 1000,
            ),
          );
        } else if (data.status === 'paused') {
          updateCountdown(Math.floor((data.remainingTime * 1000) / 1000));
        } else if (data.status === 'idle') {
          updateCountdown(Math.floor((data.duration * 1000) / 1000));
        }
      }
    });
  }, []);

  const setScoreboard = (data: Scoreboard, updateRemainingTime = false) => {
    setFirebase(firebaseDatabaseQuery, {
      ...data,
      lastUpdate: Date.now(),
      ...(!updateRemainingTime && { remainingTime: time }),
      ...(user && { lastUpdateBy: user.uid }),
    });
  };

  useEffect(() => {
    if (scoreboard.status === 'running' && !isCountRunning) {
      startCountdown();
      setIsCountRunning(true);
      return;
    }

    if (scoreboard.status === 'paused' && isCountRunning) {
      stopCountdown();
      setIsCountRunning(false);
      return;
    }
  }, [scoreboard, isCountRunning]);

  useEffect(() => {
    const { status } = scoreboard;

    if (status === 'idle') {
      stopCountdown();
      resetCountdown();
    }

    if (status === 'running') {
      startCountdown();
    }
  }, [scoreboard]);

  useEffect(() => {
    isCountRunning
      ? sendTracking('start_game_time')
      : sendTracking('pause_game_time');
  }, [isCountRunning]);

  const updateScoreboard = (data: Scoreboard) => {
    sendTracking('update_scoreboard', data);
    setScoreboard({ ...data, remainingTime: data.duration }, true);
  };

  const updateTeamA = (team: Team) => {
    sendTracking('update_team', team);
    setScoreboard({
      ...scoreboard,
      ...{ teams: { A: team, B: scoreboard.teams.B } },
    });
  };

  const updateTeamB = (team: Team) => {
    sendTracking('update_team', team);
    setScoreboard({
      ...scoreboard,
      ...{ teams: { B: team, A: scoreboard.teams.A } },
    });
  };

  const updateDuration = (duration: number) => {
    sendTracking('update_game_duration', { duration });
    setScoreboard({
      ...scoreboard,
      ...{ duration: duration },
    });
  };

  const resetRemainingTime = () => {
    setScoreboard({ ...scoreboard, remainingTime: scoreboard.duration }, true);
  };

  const postChronometer = (action: ChronometerEvents) => {
    switch (action) {
      case 'START':
        startCountdown();
        setScoreboard({
          ...scoreboard,
          ...{ status: 'running' },
        });
        break;
      case 'PAUSE':
        stopCountdown();
        setScoreboard({
          ...scoreboard,
          ...{ status: 'paused' },
        });
        break;
      case 'RESUME':
        startCountdown();
        setScoreboard({
          ...scoreboard,
          ...{ status: 'running' },
        });
        break;
      case 'RESET':
        stopCountdown();
        resetCountdown();
        setScoreboard({
          ...scoreboard,
          ...{ status: 'idle' },
        });
        break;
    }
  };

  const startChronometer = () => postChronometer('START');
  const pauseChronometer = () => postChronometer('PAUSE');
  const resumeChrometer = () => postChronometer('RESUME');
  const resetChronometer = () => postChronometer('RESET');

  const toggleChronometer = () => {
    if (isCountRunning) {
      postChronometer('PAUSE');
    } else {
      postChronometer('START');
    }
    setIsCountRunning((prev) => !prev);
  };

  const resetScoreboard = () => {
    updateCountdown(INITIAL_STATE.duration);
    setScoreboard(INITIAL_STATE);
    sendTracking('reset_scoreboard');
  };

  useEffect(() => {
    if (time <= 0) {
      pauseChronometer();
    }
  }, [time]);

  return {
    scoreboard,
    isChronometerRunning: isCountRunning,
    gameTime: time,
    updateScoreboard,
    updateTeamA,
    updateTeamB,
    updateDuration,
    startChronometer,
    pauseChronometer,
    resumeChrometer,
    resetChronometer,
    toggleChronometer,
    resetScoreboard,
    resetRemainingTime,
  };
};

export default useFirebaseScoreboard;
