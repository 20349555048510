import React, { useState } from 'react';
import {
  PlayIcon,
  PauseIcon,
  //  HandRaisedIcon,
  Cog6ToothIcon,
  MegaphoneIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import { TeamName } from '../@types.types';
import { sendTracking } from '../utils/tracking';
import useFirebaseScoreboard from '../hooks/useFirebaseScoreboard';
import Button from '../components/Admin/Button';
import Team from '../components/Admin/Team';
import GameStatus from '../components/Admin/GameStatus';
import ButtonGoogleLogin from '../components/Admin/ButtonGoogleLogin';
import ModalSettings from '../components/ModalSettings';
import useFirebaseAdmins from '../hooks/useFirebaseAdmins';
import useFirebasePubliVideo from '../hooks/useFirebasePubliVideo';

const Admin = () => {
  const [showModal, setShowModal] = useState(false);

  const { toggleIntervalVideo } = useFirebasePubliVideo();

  const { currentUserIsAdmin, currentUser } = useFirebaseAdmins();

  const {
    scoreboard,
    gameTime,
    updateTeamA,
    updateTeamB,
    toggleChronometer,
    resetScoreboard,
    resetRemainingTime,
  } = useFirebaseScoreboard(currentUser);

  const { teams, status: gameStatus } = scoreboard;

  const updateGoalsHandler = (
    team: TeamName,
    updateType: 'increment' | 'drecrement',
  ) => {
    const goalsParsed =
      teams[team].goals + (updateType === 'increment' ? 1 : -1);
    const goals = goalsParsed < 0 ? 0 : goalsParsed;

    const content = {
      ...teams[team],
      ...{ goals: goals },
    };

    sendTracking('change_team_name', { team });

    team === 'A' ? updateTeamA(content) : updateTeamB(content);
  };

  if (!currentUserIsAdmin()) {
    return <ButtonGoogleLogin />;
  }

  return (
    <>
      <div className="min-h-screen w-full bg-zinc-900">
        <GameStatus
          gameTime={gameTime}
          gameStatus={gameStatus}
          onResetTimer={() => resetRemainingTime()}
        />
        <Team
          {...teams.A}
          onIncrementGoals={() => updateGoalsHandler('A', 'increment')}
          onDecrementGoals={() => updateGoalsHandler('A', 'drecrement')}
        />
        <Team
          {...teams.B}
          onIncrementGoals={() => updateGoalsHandler('B', 'increment')}
          onDecrementGoals={() => updateGoalsHandler('B', 'drecrement')}
        />
        <div className="grid grid-cols-3 gap-4 p-8">
          <Button
            icon={gameStatus === 'running' ? PauseIcon : PlayIcon}
            onClick={() => toggleChronometer()}
          />
          {/* <Button icon={HandRaisedIcon} /> */}
          <Button icon={MegaphoneIcon} onClick={() => toggleIntervalVideo()} />
          <Button icon={ArrowPathIcon} onClick={() => resetScoreboard()} />
          <Button icon={Cog6ToothIcon} onClick={() => setShowModal(true)} />
        </div>
      </div>
      {showModal ? (
        <ModalSettings handleCloseModal={() => setShowModal(false)} />
      ) : null}
    </>
  );
};

export default Admin;
