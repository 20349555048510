import React from 'react';
import Logo from '../../assets/logo-campeonato-2024.png';

interface ScoreboardProps {
  teamAName: string;
  teamAGoals: number;
  teamBName: string;
  teamBGoals: number;
}

const Scoreboard = ({
  teamAName,
  teamAGoals,
  teamBName,
  teamBGoals,
}: ScoreboardProps) => {
  return (
    <div className="w-full flex px-16 relative mb-4">
      <div className="w-full h-[477px]">
        <div className="w-full h-full relative z-10 flex items-center">
          <div className="flex-1 h-full text-center py-8 flex flex-col justify-between min-w-[50%] pr-[238.5px]">
            <h2 className="text-8xl uppercase text-white font-black leading-none tracking-tight">
              {teamAName}
            </h2>
            <h3 className="text-[14rem] text-yellow font-black leading-none tracking-tight">
              {teamAGoals.toString().padStart(2, '0')}
            </h3>
          </div>
          <div className="h-full w-[477px] absolute right-1/2 mr-[-238.5px]">
            <img src={Logo} width={'477'} />
          </div>
          <div className="flex-1 h-full text-center py-8 flex flex-col justify-between min-w-[50%] pl-[238.5px]">
            <h2 className="text-8xl uppercase text-white font-black leading-none tracking-tight">
              {teamBName}
            </h2>
            <h3 className="text-[14rem] text-yellow font-black leading-none tracking-tight">
              {teamBGoals.toString().padStart(2, '0')}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scoreboard;
