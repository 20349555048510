// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyCDNB-qShsMEqU4NLbTCWIEFu86S7X4Kd8',
  authDomain: 'placar-interativo.firebaseapp.com',
  projectId: 'placar-interativo',
  storageBucket: 'placar-interativo.appspot.com',
  messagingSenderId: '539882291048',
  appId: '1:539882291048:web:8b6d2e3862d0d743ea20dd',
  measurementId: 'G-H2ESGYX6RX',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: 'select_account ',
});

export const auth = getAuth();

export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export { database, analytics, auth as firebaseAuth };
