import React, { useEffect, useRef, useState } from 'react';
import useFirebasePubliVideo from '../hooks/useFirebasePubliVideo';
import { formatTime } from '../utils/formatTime';
import useFirebaseScoreboard from '../hooks/useFirebaseScoreboard';
import clsx from 'clsx';
import Scoreboard from '../components/App/Scoreboard';
import IntervalAdvertising from '../components/App/IntervalAdvertising';
import AnimationGoal from '../components/App/AnimationGoal';
import Video from '../components/App/Video';

const App = () => {
  const [shouldShowGoalAnimation, setShouldShowGoalAnimation] = useState(false);
  const { scoreboardVideo } = useFirebasePubliVideo();
  const [teamGoalAnimation, setTeamGoalAnimation] = useState<
    string | undefined
  >();

  const { scoreboard, gameTime } = useFirebaseScoreboard();
  const {
    teams: { A: teamA, B: teamB },
    showingTime: shouldShowTime,
  } = scoreboard;

  const { goals: goalsTeamA } = teamA;
  const prevGoalsTeamA = useRef<number>(0);

  useEffect(() => {
    if (goalsTeamA > prevGoalsTeamA.current) {
      setTeamGoalAnimation(teamA.name);
      setShouldShowGoalAnimation(true);
    }
    prevGoalsTeamA.current = goalsTeamA;
  }, [goalsTeamA, teamA]);

  const { goals: goalsTeamB } = teamB;
  const prevGoalsTeamB = useRef<number>(0);

  useEffect(() => {
    if (goalsTeamB > prevGoalsTeamB.current) {
      setTeamGoalAnimation(teamB.name);
      setShouldShowGoalAnimation(true);
    }
    prevGoalsTeamB.current = goalsTeamB;
  }, [goalsTeamB, teamB]);

  useEffect(() => {
    if (!shouldShowGoalAnimation) return;

    let interval = setInterval(() => {
      setShouldShowGoalAnimation(false);
    }, 10000);

    return () => clearInterval(interval);
  }, [shouldShowGoalAnimation]);

  const isRunningOutTime = gameTime <= 15;

  return (
    <>
      <div className="w-full h-lvh bg-black font-['Montserrat'] pt-8 pb-4 px-6">
        {shouldShowTime ? (
          <h1
            className={clsx(
              'font-black text-9xl text-center mb-12 text-white',
              {
                'animate-blink': isRunningOutTime,
              },
            )}
          >
            {formatTime(gameTime)}
          </h1>
        ) : null}

        <Scoreboard
          teamAName={teamA.name}
          teamAGoals={teamA.goals}
          teamBName={teamB.name}
          teamBGoals={teamB.goals}
        />

        <div className="w-full">
          <div
            className={clsx('aspect-video mx-auto', {
              'w-[930px]': !shouldShowTime,
              'w-[650px]': shouldShowTime,
            })}
          >
            <Video videoUrl={scoreboardVideo} />
          </div>
        </div>
      </div>

      <IntervalAdvertising />

      <AnimationGoal
        shouldShow={shouldShowGoalAnimation}
        teamName={teamGoalAnimation ?? ''}
        videoUrl={scoreboardVideo}
      />
    </>
  );
};

export default App;
