import React from 'react';
import { signInWithGooglePopup } from '../../clients/firebase';

const ButtonGoogleLogin = () => {
  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    console.log(response);
  };

  return <button onClick={logGoogleUser}>Fazer login com o Google</button>;
};
export default ButtonGoogleLogin;
