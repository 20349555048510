import React, { ForwardRefExoticComponent, useCallback } from 'react';

interface ButtonProps {
  icon: ForwardRefExoticComponent<any>;
  shortcutSymbol?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = ({
  icon: Icon,
  onClick,
  shortcutSymbol,
  disabled,
}: ButtonProps) => {
  const onClickHandler = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <button
      className="w-full aspect-square text-white-200 border border-white-300 rounded-lg relative disabled:opacity-50 disabled:cursor-not-allowed"
      onClick={onClickHandler}
      disabled={disabled}
    >
      <Icon className="w-4/6 aspect-square mx-auto" />
      {shortcutSymbol && (
        <span className="text-xs absolute right-0 left-0 bottom-0">
          [ {shortcutSymbol} ]
        </span>
      )}
    </button>
  );
};

export default Button;
