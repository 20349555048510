import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Logo from '../../assets/logo-campeonato-2024.png';
import Video from './Video';

interface AnimationGoalProps {
  teamName: string;
  videoUrl: string;
  shouldShow: boolean;
}

interface SlideProps extends Partial<AnimationGoalProps> {
  className?: string;
}

const SlideGoal = ({ teamName, className }: SlideProps) => (
  <div
    className={`w-full h-full flex flex-col justify-center align-middle text-center animate-fade ${className}`}
  >
    <h1 className="text-[26rem] text-yellow font-black italic leading-none tracking-tight">
      GOL!!!
    </h1>
    <h2 className="text-[10rem] text-white font-black leading-none tracking-tight uppercase">
      {teamName}
    </h2>
  </div>
);

const SlideAdvertising = ({
  className,
  videoUrl,
}: SlideProps & { videoUrl: string }) => (
  <div className={`w-full h-full grid grid-cols-2 animate-fade ${className}`}>
    <div className="flex justify-center items-center">
      <img src={Logo} width={'700'} />
    </div>
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-[6rem] uppercase text-white font-black leading-none tracking-tight">
        Apoio:
      </h1>
      <div className="w-[700px] aspect-video mx-auto">
        <Video videoUrl={videoUrl} />
      </div>
    </div>
  </div>
);

const COUNTER_SLIDE = 2;

const AnimationGoal = ({
  teamName,
  videoUrl,
  shouldShow,
}: AnimationGoalProps) => {
  const [counter, setCounter] = useState(1);
  const [pause, setPause] = useState(true);

  const handleNext = () => {
    if (counter !== COUNTER_SLIDE) {
      setCounter(counter + 1);
    } else {
      setCounter(COUNTER_SLIDE);
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (!pause) {
        handleNext();
      }
    }, 4500);
    return () => clearInterval(interval);
  }, [pause]);

  useEffect(() => {
    setPause(!shouldShow);
    if (!shouldShow) {
      setCounter(1);
    }
  }, [shouldShow]);

  return (
    <div
      className={clsx('fixed h-full inset-0 z-40 bg-black', {
        'top-[100%]': !shouldShow,
      })}
    >
      <SlideGoal
        teamName={teamName}
        className={clsx({ hidden: counter !== 1 })}
      />
      <SlideAdvertising
        videoUrl={videoUrl}
        className={clsx({ hidden: counter !== 2 })}
      />
    </div>
  );
};

export default AnimationGoal;
